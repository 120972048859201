import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/cartStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import loader from "assets/img/Tuzo-Preloader-Transparent.gif";
import tuzoLogo from "assets/img/tuzo/Tuzo-square.png";

// core components
import { Hidden, Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';


export function ContactInfo(props) {
    const {
        classes,
        user
    } = props;

    return (
        <div>
            <Hidden mdUp implementation='css'>
                <Card className={classes.card}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Typography className={classes.totalCost}>
                                Contact Info
                            </Typography >
                        </GridItem>

                        <GridItem xs={7}>
                            <Typography className={classes.cardDescription}>
                                {user.email}
                            </Typography >
                        </GridItem>

                        <GridItem xs={5}>
                            <Typography className={classes.cardDescription}>
                                {user.cellPhone}
                            </Typography >
                        </GridItem>
                    </GridContainer>
                </Card>
            </Hidden>

            <Hidden smDown implementation='css'>
                <Card className={classes.card}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Typography className={classes.totalCost}>
                                Contact Info
                            </Typography >
                        </GridItem>

                        <GridItem xs={4}>
                            <Typography className={classes.cardDescription}>
                                {user.email}
                            </Typography >
                        </GridItem>

                        <GridItem xs={4}>
                            <Typography className={classes.cardDescription}>
                                {user.cellPhone}
                            </Typography >
                        </GridItem>
                    </GridContainer>
                </Card>
            </Hidden>
        </div>

    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ContactInfo);