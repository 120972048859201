/*eslint-disable*/
import React, { useEffect } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from "moment"

// mui core
import { Button, Card, Switch, Typography } from "@material-ui/core";

//components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";



//styling
import withStyles from '@material-ui/core/styles/withStyles';
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import WishListTable from './WishListTable';


export function UserInfo(props) {
    const {
        classes,
        history,
        formatPhoneNumber,
        merchantData,
        wishListData,
        pageNumber,
        setPageNumber,
        index,
        setIndex,
        endIndex,
        setEndIndex,
        itemsPerPage,
        handlePageClick,
        rewardsData,
        userCompany,
        merchantId,
        user
    } = props;

    return (
        <div>
            <GridContainer>
                <GridItem xs={4} className={classes.tighterGrid}>
                    <Card className={classes.profileCardStyling2} style={{ overflow: "visible" }}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <Typography variant="h2" className={classes.cardHeader}>
                                    Personal Details
                                </Typography>
                            </GridItem>

                            <GridItem xs={4} style={{ marginTop: "20px" }}>
                                {merchantData.profilePic ?
                                    <div>
                                        <img
                                            className={classes.img}
                                            src={merchantData.profilePic}
                                        />
                                    </div>
                                    :
                                    <div className={classes.noProfilePic}>
                                        {merchantData.firstName[0] + merchantData.lastName[0]}
                                    </div>
                                }
                            </GridItem>

                            <GridItem xs={8}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Username
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.username ? merchantData.username : "N/A"}
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            First Name
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.firstName ? merchantData.firstName : "N/A"}
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Last Name
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.lastName ? merchantData.lastName : "N/A"}
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Email (required)
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeightEmail}>
                                            {merchantData.email ? merchantData.email : "N/A"}
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Company
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.companyName ? merchantData.companyName : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </Card>
                </GridItem>

                <GridItem xs={4}>
                    <Card className={classes.profileCardStyling2}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <Typography variant="h2" className={classes.cardHeader}>
                                    Contact Information
                                </Typography>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Contact First Name
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.contactFirstName ? merchantData.contactFirstName : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Contact Last Name
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.contactLastName ? merchantData.contactLastName : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Phone Number
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.cellPhone ? formatPhoneNumber(merchantData.cellPhone) : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Secondary Phone
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.secondaryPhone ? formatPhoneNumber(merchantData.secondaryPhone) : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Shipping Address
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.shippingAddress ? merchantData.shippingAddress : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </Card>
                </GridItem>

                <GridItem xs={4}>
                    <Card className={classes.profileCardStyling2}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <Typography variant="h2" className={classes.cardHeader}>
                                    Platform Metrics
                                </Typography>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Creation Date
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.timestamp ? moment.unix(merchantData.timestamp.seconds).format("MM/DD/YYYY") : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Last Logged In
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.lastLoggedIn ? moment.unix(merchantData.lastLoggedIn.seconds).format("MM/DD/YYYY hh:mm a") : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Login Count
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.loginCount ? merchantData.loginCount : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Eligible Rewards
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {rewardsData.length}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Rewards Redeemed
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.rewardsRedeemed ? merchantData.rewardsRedeemed : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </Card>
                </GridItem>

                <GridItem xs={6} style={{ marginTop: "20px" }}>
                    <Card className={classes.profileCardStyling2}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <Typography variant="h2" className={classes.cardHeader}>
                                    Contract Details
                                </Typography>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Basis Points Set Aside
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.userBasisPts ? merchantData.userBasisPts : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Sign-Up Bonus
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.signUpBonus ? merchantData.signUpBonus : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Auto Approve Points
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.autoApprove ? "Enabled" : "Disabled"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} style={{}}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Points Release Schedule
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.ptsReleaseDay ? merchantData.ptsReleaseDay + " Days" : "60 Days"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </Card>
                </GridItem>

                <GridItem xs={6} style={{ marginTop: "20px" }}>
                    <Card className={classes.profileCardStyling2}>
                        <GridContainer>
                            <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="h2" className={classes.cardHeader}>
                                    Latest Wishlist Items
                                </Typography>

                                <Button
                                    className={classes.viewWishListButton}
                                    onClick={() => history.push(`/iso-admin/${userCompany}/merchant-wish-list/${merchantId}`)}
                                >
                                    <ViewIcon className={classes.icon} /> View Full WishList
                                </Button>
                            </GridItem>

                            <GridItem xs={12}>
                                <WishListTable
                                    wishListData={wishListData}
                                    classes={classes}
                                    itemsPerPage={itemsPerPage}
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumber}
                                    index={index}
                                    setIndex={setIndex}
                                    endIndex={endIndex}
                                    setEndIndex={setEndIndex}
                                    handlePageClick={handlePageClick}
                                />
                            </GridItem>
                        </GridContainer>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userCompany: state.auth.user.companyName,
        user: state.auth.user
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
)(UserInfo));