import React, { useEffect } from 'react';
import { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from "moment"
import { db } from 'firebase/client';
import Table from './components/Table';
import MobileTable from './components/MobileTable';
import { CSVLink } from "react-csv";

// mui
import { Button, Card, Hidden, Typography } from "@material-ui/core";

// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/reportsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import loader from "assets/img/Tuzo-Preloader-Transparent.gif";
import tuzoBg from "assets/img/tuzo/tallBg.png";
import { ptsFormula } from 'globalCalculationFunction/globalCalculationFunction';
import { MdKeyboardArrowLeft } from 'react-icons/md';

// icons
import { ReactComponent as CSVIcon } from "assets/img/tuzo/icons/CSVIcon.svg";
import { zip } from 'lodash';


const headers = [
    { label: "Payment Method", key: "paymentMethod" },
    { label: "Reference", key: "referenceNum" },
    { label: "Date", key: "date" },
    { label: "Amount", key: "Amount" },
    { label: "Points", key: "pts" },
];

const csvHeaders = [
    { label: "merchantName", key: "merchantName" },
    { label: "merchantNumber", key: "merchantNumber" },
    { label: "email", key: "email" },
    { label: "reportDate", key: "reportDate" },
    { label: "authorizationNumber", key: "authorizationNumber" },
    { label: "cardDescription", key: "cardDescription" },
    { label: "cardNumber", key: "cardNumber" },
    { label: "cardType", key: "cardType" },
    { label: "entryModeDescription", key: "entryModeDescription" },
    { label: "keyed", key: "keyed" },
    { label: "transactionAmount", key: "transactionAmount" },
    { label: "transactionCode", key: "transactionCode" },
    { label: "transactionDate", key: "transactionDate" },
    { label: "transactionDescription", key: "transactionDescription" },
    { label: "transactionID", key: "transactionID" },
    { label: "transactionTime", key: "transactionTime" },
];

export function IsoAdminBatchDetails(props) {
    const { classes, history, user } = props;
    const [detailsData, setDetailsData] = useState(false)
    const [batchData, setBatchData] = useState(false)
    const [merchantName, setMerchantName] = useState("")
    const itemsPerPage = 9;
    const itemsPerPageMobile = 3;
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [mobileEndIndex, setMobileEndIndex] = useState(itemsPerPageMobile)
    const [points, setPoints] = useState([])
    const [docId, setDocId] = useState("")
    const [subId, setSubId] = useState("")
    const [userBasisPoints, setUserBasisPoints] = useState(0)
    const [zippedArr, setZippedArr] = useState(false)


    const numberFormatterDecimals = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    useEffect(() => {
        if (user.userType === "IsoMerchants") {
            setDocId(window.location.pathname.split("/")[6])
            setSubId(window.location.pathname.split("/")[7])
        } else if (user.userType === "Admin" || user.userType === "Merchants") {
            setDocId(window.location.pathname.split("/")[4])
            setSubId(window.location.pathname.split("/")[5])
        }
        else {
            setDocId(window.location.pathname.split("/")[5])
            setSubId(window.location.pathname.split("/")[6])
        }
    }, [])

    useEffect(() => {
        if (detailsData) {
            var arr = []
            setPoints([])
            detailsData.map((d) => {
                const floorDollarPerBassisPts = ptsFormula(Number(d.transactionAmount), d.userBasisPts)
                arr.push(floorDollarPerBassisPts)
                setPoints(prevArr => [...prevArr, floorDollarPerBassisPts])
            })
            setZippedArr(zip(detailsData, arr))
        } else {
            setZippedArr([])
        }
    }, [detailsData])

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage);
        setEndIndex(selected * itemsPerPage + itemsPerPage);
    };

    useEffect(() => {
        if (!batchData && docId) {
            let mid = ""
            let userBasisPts = 0
            let email = ""
            const getData = () => {
                const batchDb = db.collection('merchants-live').doc(docId).collection('batch-summary').doc(subId)
                const merchDb = db.collection('merchants-live').doc(docId)

                return merchDb
                    .get()
                    .then(async (snapshot) => {
                        setMerchantName(snapshot.data().merchantName)
                        mid = snapshot.data().merchantNumber
                        email = snapshot.data().email

                        await db.collection('users')
                            .where("merchantId", "==", mid)
                            .get()
                            .then((snapshots) => {
                                if (!snapshots.empty) {
                                    snapshots.forEach((s) => {
                                        s = s.data()
                                        userBasisPts = s.userBasisPts
                                        setUserBasisPoints(s.userBasisPts)
                                    })
                                } else {
                                    
                                }
                            })
                        // await db.collection('iso-temp-user')
                        //     .where("merchantId", "==", mid)
                        //     .get()
                        //     .then((snapshots) => {
                        //         if (!snapshots.empty) {
                        //             snapshots.forEach((s) => {
                        //                 s = s.data()
                        //                 userBasisPts = s.userBasisPts
                        //                 setUserBasisPoints(s.userBasisPts)
                        //             })
                        //         } else {
                        //             // return
                        //         }
                        //     })
                    })
                    .then(() => {
                        batchDb
                            .get()
                            .then((snapshot) => {
                                setBatchData(snapshot.data())
                            })
                            .then(() => {
                                batchDb.collection('batch-details')
                                    .orderBy('transactionDate', 'asc')
                                    .onSnapshot((snapshot) => {
                                        const arr = [];
                                        snapshot.forEach((doc) => {
                                            const newObj = { ...doc.data(), userBasisPts, merchantNumber: mid, email }
                                            arr.push(newObj)
                                        })
                                        setDetailsData(arr)
                                    })
                            })
                    })
            }
            getData()
        }
    },[docId])

    const getBgImage = () => {
        return tuzoBg;
    }

    if (!batchData || zippedArr.length < 1 || !docId || !subId)
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop:"10%"
                }}
            >
                <img style={{ width: "20%" }} src={loader} alt="..." />
            </div>
        );

    return (
        <div>
            <Hidden mdUp implementation='css'>
                <GridContainer style={{ marginTop: "50px" }}>
                    <GridItem xs={12}>
                        <Typography variant="h2" className={classes.titleMobile}>
                            Batch #{batchData.batchNumber} Details
                        </Typography>
                    </GridItem>

                    <GridItem xs={12}>
                        <Button
                            onClick={() => {
                                history.push(`/iso-merchant/${(user.companyName).replace(/ /g, '').toLowerCase()}/reports/sales-by-merchant`)
                            }}
                            className={classes.backButton}>
                            <MdKeyboardArrowLeft
                                style={{ fontSize: "32px", marginTop: "-2px" }} />
                            Back to Reports
                        </Button>
                    </GridItem>
                </GridContainer>

                <div
                    style={{
                        backgroundImage: "url(" + getBgImage() + ")",
                        width: "100vw",
                        marginLeft: "-30px",
                        height: "100%",
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#274b73",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        padding: "20px"
                    }}
                >

                    <GridContainer>
                        <GridItem xs={12}>
                            <Card className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Batch Total
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Transactions
                                        </Typography >
                                    </GridItem>

                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfoBoldTopMobile}>
                                            ${Number(batchData.netAmount).toFixed(2)}
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfoBoldMobile}>
                                            {batchData.transactionCount}
                                        </Typography >
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>

                        <GridItem xs={12} className={classes.marginTopGrid}>
                            <Card className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Batch Date
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Time Posted
                                        </Typography >
                                    </GridItem>

                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            {moment(batchData.reportDate).format("MMM DD, YYYY")}
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            {moment(batchData.reportDate).format("LT")}
                                        </Typography >
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>

                        <GridItem xs={12} className={classes.marginTopGrid}>
                            <Card className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Points Earned
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfoMobileLineheight}>
                                            Transaction Location
                                        </Typography >
                                    </GridItem>

                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            {ptsFormula(batchData.netAmount, userBasisPoints)}
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfoMobileLineheight}>
                                            {merchantName}
                                        </Typography >
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>

                <GridContainer>
                    <GridItem xs={12} style={{ marginTop: "15px", alignContent: "center" }}>
                        <Typography
                            variant="h2"
                            className={classes.title}
                            style={{ fontSize: "19px", lineHeight: "19px" }}
                        >
                            {batchData.transactionCount} Transactions
                        </Typography>
                    </GridItem>

                    <GridItem xs={12}>
                        <MobileTable
                            detailsData={zippedArr}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            index={index}
                            setIndex={setIndex}
                            endIndex={mobileEndIndex}
                            setEndIndex={setMobileEndIndex}
                            itemsPerPage={itemsPerPageMobile}
                            headers={headers}
                            history={history}
                            points={points}
                        />
                    </GridItem>
                </GridContainer>
            </Hidden>

            <Hidden smDown implementation="css">
                <GridContainer >
                    <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h2" className={classes.title}>
                            Batch #{batchData.batchNumber} Details
                        </Typography>

                        <Button
                            onClick={() => {
                                history.push(`/iso-admin/${(user.companyName).replace(/ /g, '').toLowerCase()}/reports/sales-by-merchant`)
                            }}
                            className={classes.backButton}>
                            <MdKeyboardArrowLeft
                                style={{ fontSize: "32px", marginTop: "-2px" }} />
                            Back to Reports
                        </Button>
                    </GridItem>
                </GridContainer>

                <div
                    style={{
                        backgroundImage: "url(" + getBgImage() + ")",
                        width: "100vw",
                        marginLeft: "-30px",
                        height: "225px",
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#274b73",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        padding: "20px"
                    }}
                >

                    <GridContainer>
                        <GridItem xs={4}>
                            <Card className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Batch Total
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Transactions
                                        </Typography >
                                    </GridItem>

                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfoBoldTop}>
                                            ${Number(batchData.netAmount).toFixed(2)}
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfoBold}>
                                            {batchData.transactionCount}
                                        </Typography >
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>

                        <GridItem xs={4}>
                            <Card className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Batch Date
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Time Posted
                                        </Typography >
                                    </GridItem>

                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            {moment(batchData.reportDate).format("MMM DD, YYYY")}
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            {moment(batchData.reportDate).format("LT")}
                                        </Typography >
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>

                        <GridItem xs={4}>
                            <Card className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Points Earned
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            Transaction Location
                                        </Typography >
                                    </GridItem>

                                    <GridItem xs={6} className={classes.columnGrid}>
                                        <Typography variant="h2" className={classes.cardInfo}>
                                            {ptsFormula(batchData.netAmount, userBasisPoints)}
                                        </Typography >

                                        <Typography variant="h2" className={classes.cardInfo}>
                                            {merchantName}
                                        </Typography >
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>

                <GridContainer>
                    <GridItem xs={6} style={{ marginTop: "15px", alignContent: "center" }}>
                        <Typography
                            variant="h2"
                            className={classes.title}
                            style={{ fontSize: "19px", lineHeight: "19px" }}
                        >
                            {batchData.transactionCount} Transactions
                        </Typography>
                    </GridItem>

                    {/* <GridItem xs={6} style={{ marginTop: "15px", display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            className={classes.csvButton}
                            style={{ width: "30%" }}
                        >
                            <CSVLink
                                style={{ color: "white" }}
                                headers={csvHeaders}
                                data={
                                    detailsData.map(d => ({
                                        merchantName: merchantName,
                                        merchantNumber: d.merchantNumber,
                                        email: d.email,
                                        reportDate: moment(d.reportDate).format("MM/DD/YY"),
                                        authorizationNumber: d.authorizationNumber,
                                        cardDescription: d.cardDescription,
                                        cardNumber: d.cardNumber,
                                        cardType: d.cardType,
                                        entryModeDescription: d.entryModeDescription,                                   
                                        keyed: d.keyed,
                                        transactionAmount: numberFormatterDecimals.format(d.transactionAmount),
                                        transactionCode: d.transactionCode,
                                        transactionDate: moment(d.transactionDate).format("MM/DD/YY"),
                                        transactionDescription: d.transactionDescription,
                                        transactionID: d.transactionID,
                                        transactionTime: moment(d.transactionTime, "hh:mm:ss").format("hh:mm A"),

                                    }))
                                }
                                filename={`batch_summary_details`}
                            >
                                <CSVIcon style={{ marginRight: "10px", marginBottom: "-5px" }} /> Export CSV
                            </CSVLink>
                        </Button>
                    </GridItem> */}

                    <GridItem xs={12}>
                        <Table
                            detailsData={zippedArr}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            index={index}
                            setIndex={setIndex}
                            endIndex={endIndex}
                            setEndIndex={setEndIndex}
                            itemsPerPage={itemsPerPage}
                            handlePageClick={handlePageClick}
                            headers={headers}
                            history={history}
                            points={points}
                        />
                    </GridItem>
                </GridContainer>
            </Hidden>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(IsoAdminBatchDetails));