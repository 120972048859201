import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Preview from './Preview';
import { db } from 'firebase/client';
import allRewards from "assets/img/tuzo/allRewards.png"
import { CopyToClipboard } from 'react-copy-to-clipboard';

// styling 
import Style from "assets/jss/material-dashboard-pro-react/views/calculatorStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "assets/img/Tuzo-Preloader-Transparent.gif";

// core components
import { Button, Hidden, Snackbar, TextField, Typography } from '@material-ui/core';
import { ArrowBackIos, CheckCircle } from '@material-ui/icons';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Alert from "@material-ui/lab/Alert";
// global function 
import { ptsFormula } from 'globalCalculationFunction/globalCalculationFunction';

// @material-ui/icons
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as Copy } from "assets/img/tuzo/icons/CopyIcon.svg";


export function Calculator(props) {
    const { classes, history, userLogo } = props;
    const merchantName = window.location.pathname.split('/')[2]
    const [grossAmt, setGrossAmt] = useState("")
    const [basisPts, setBasisPts] = useState(20)
    const [data, setData] = useState(false)
    const [points, setPoints] = useState("")
    const [goNext, setGoNext] = useState(false)
    const [error, setError] = useState(false)
    const [arr, setArr] = useState(false)
    const [timesRan, setTimesRan] = useState(1)
    let count = 1
    let oldPts = 0

    const formatInput = (value) => {
        let newValue = value.replace(/[^0-9\.]/g, '').replace(/\./g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        setGrossAmt(newValue)
    }

    const formatBasis = (value) => {
        let newValue = value.replace(/[^0-9\.]/g, '').replace(/\./g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        setBasisPts(newValue)
    }

    const queryPts = (pts) => {
        const items = []
        db
            .collection("rewards-list-test")
            .orderBy('salePricePoints', 'desc')
            .where("salePricePoints", "<=", pts)
            .onSnapshot((querySnapshot) => {
                if (count == 1 && querySnapshot.docs.length < 4) {
                    count = count + 1
                    setTimesRan((prev) => prev + 1)
                    queryPts(pts * 12)
                }
                else if (count > 1 && querySnapshot.docs.length < 4) {
                    count = count + 1
                    setTimesRan((prev) => prev + 1)
                    queryPts(pts * 3)
                }
                else {
                    querySnapshot.forEach((doc) => {
                        items.push(doc.data())
                    });
                    setArr(items)
                }
            })
    }


    const ptsCalculator = () => {
        let replacedgrossAmt = grossAmt.replace(/,/g, '')
        if (!grossAmt) {
            setError(true)
        }
        else {
            let basisPtsFunction = data ? data.calculatorBasisPts : 20
            const floorDollarPerBassisPts = ptsFormula(replacedgrossAmt, basisPtsFunction)
            
            oldPts = floorDollarPerBassisPts
            setPoints(floorDollarPerBassisPts)
            queryPts(oldPts)
            setError(false)
            setGoNext(true)
        }
    }

    useEffect(() => {
        if(!data){
            const getData = () => {
                db.collection('users')
                .where('companyName', '==', merchantName)
                .get()
                .then((snapshots) => {
                    if(!snapshots.empty){
                        snapshots.forEach((doc) => {
                            doc = doc.data()
                            setData(doc)
                        })
                    }
                    else{
                        setData({})
                    }
                })
            } 
            getData()
        }
    })
   
    if (!data) {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop:"10%"
                }}
            >
                <img style={{ width: "20%" }} src={loader} alt="..." />
            </div>
        )
    }

    if (goNext) {
        return <Preview grossAmt={grossAmt} points={points} arr={arr} timesRan={timesRan} />
    }
    return (
        <div>
            <Hidden mdUp implementation='css'>
                <div className={classes.calculatorCardIsoAdminMobile} >
                    <div>
                        <Typography className={classes.calculatorTitleMobile}>
                            The Tuzo Rewards Calculator
                        </Typography>

                        <Typography className={classes.calculatorSubtitleMobile} style={{ paddingRight: "5%", paddingLeft: "5%" }}>
                            Calculate how many rewards points you can earn!
                        </Typography>
                    </div>
                    <div>
                        <div>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Typography className={classes.cardHeaderTitleMobile} style={{ paddingRight: "5%", paddingLeft: "5%" }}>
                                        Enter your store’s gross monthly processing amount ($) <span style={{ color: "#E53935" }}>*</span>
                                        {error ? (
                                            <span style={{ color: "red" }}>Required</span>
                                        ) : null}
                                    </Typography>
                                    <TextField
                                        className={classes.textFieldMobile}
                                        value={grossAmt}
                                        onChange={(e) => formatInput(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                ptsCalculator()
                                            }
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                input: classes.input
                                            },
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} className={classes.gridPadding}>
                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <Link to="/admin/actions" target="_blank" rel="noopener noreferrer">
                                            <Button
                                                className={classes.goBackButtonMobile}
                                            >
                                                <ArrowBackIos style={{ marginRight: "10px", marginTop: "-3px" }} /> Go to App
                                            </Button>
                                        </Link>

                                        <Button
                                            className={classes.submitButtonMobile}
                                            onClick={() => {
                                                ptsCalculator()
                                            }}
                                        >
                                            <CheckCircle style={{ marginRight: "10px", marginTop: "-5px" }} /> Submit
                                        </Button>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={allRewards} style={{ padding: "5%", width: "300px", maxHeight: "450px", marginRight: "20px" }} />
                        </div>
                    </div>
                </div>
            </Hidden>


            <Hidden smDown implementation='css'>
                <div className={classes.calculatorCardIsoAdmin}>
                    <div>
                        <Typography className={classes.calculatorTitle}>
                            The Tuzo Rewards Calculator
                        </Typography>

                        <Typography className={classes.calculatorSubtitle}>
                            Calculate how many rewards points you can earn!
                        </Typography>
                    </div>
                    <div className={classes.calculatorCard}>
                        <div>
                            <Typography className={classes.cardHeaderTitle}>
                                Enter your store’s gross monthly processing amount ($) <span style={{ color: "#E53935" }}>*</span>
                                {error ? (
                                    <span style={{ color: "red" }}>Required</span>
                                ) : null}
                            </Typography>
                            <TextField
                                className={classes.textField}
                                value={grossAmt}
                                onChange={(e) => formatInput(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        ptsCalculator()
                                    }
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                        input: classes.input
                                    },
                                }}
                            />
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <Link to="/admin/actions" target="_blank" rel="noopener noreferrer">
                                    <Button
                                        className={classes.goBackButton}
                                    >
                                        <ArrowBackIos style={{ marginRight: "10px", marginTop: "-3px" }} /> Go to App
                                    </Button>
                                </Link>
                                <Button
                                    className={classes.submitButton}
                                    onClick={() => {
                                        ptsCalculator()
                                    }}
                                >
                                    <CheckCircle style={{ marginRight: "10px", marginTop: "-5px" }} /> Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={allRewards} style={{ width: "450px", padding: "5%", maxHeight: "450px", marginRight: "65px" }} />
                        {data.logo ?
                            <img src={data.logo} style={{ width: "450px", padding: "5%", maxHeight: "450px", marginRight: "65px" }} />
                            : null}
                    </div>
                </div>
            </Hidden>
        </div>

    )

}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Calculator));