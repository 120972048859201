import React, { useEffect } from 'react';
import { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from "moment"
import { db } from 'firebase/client';
import Filter from './components/Filter';
import Table from './components/Table';
import { reportSelections } from '../reportSelections';

// mui
import { Card, Hidden, Typography } from "@material-ui/core";

// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/reportsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import loader from "assets/img/Tuzo-Preloader-Transparent.gif";
import { zip } from 'lodash';
import { ptsFormula } from 'globalCalculationFunction/globalCalculationFunction';
import MobileTable from './components/MobileTable';



const headersDaily = [
    { label: "Date", key: "date" },
    { label: "# of Transactions", key: "numTransactions" },
    { label: "Total ($)", key: "total" },
    { label: "Reference", key: "referenceNum" },
    { label: "Points", key: "pts" },
];

const headersMonthly = [
    { label: "Date", key: "date" },
    { label: "# of Batches", key: "numBatches" },
    { label: "Total ($)", key: "total" },
    { label: "Reference", key: "referenceNum" },
    { label: "Points", key: "pts" },
];

export function ReportsPage(props) {
    const { classes, history, userType, userMid, user } = props;
    const [reportArr, setReportArr] = useState([])
    const [sortValue, setSortValue] = useState("")
    const [reportType, setReportType] = useState("Monthly")
    const [dateFilter, setDateFilter] = useState(moment().format("MMM DD, YYYY"))
    const itemsPerPage = 9;
    const itemsPerPageMobile = 3;
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [mobileEndIndex, setMobileEndIndex] = useState(itemsPerPageMobile)
    const [batchData, setBatchData] = useState(false)
    const [data, setData] = useState([])
    const [headers, setHeaders] = useState(null)
    const [points, setPoints] = useState([])
    const [docId, setDocId] = useState("")
    const [zippedArr, setZippedArr] = useState([])

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage);
        setEndIndex(selected * itemsPerPage + itemsPerPage);
    };

    const handleReportToggle = (value) => {
        setReportType(value)
    }

    const increaseDate = (date) => {
        let newDate = ""
        if (reportType === "Monthly") {
            newDate = moment(date, "MMM YYYY").add(1, 'months').format("MMM YYYY")
        }
        else {
            newDate = moment(date, "MMM DD, YYYY").add(1, 'd').format("MMM DD, YYYY")
        }

        setDateFilter(newDate)
    }

    const decreaseDate = (date) => {
        let newDate = ""
        if (reportType === "Monthly") {
            newDate = moment(date, "MMM YYYY").subtract(1, 'months').format("MMM YYYY")
        }
        else {
            newDate = moment(date, "MMM DD, YYYY").subtract(1, 'd').format("MMM DD, YYYY")
        }
        setDateFilter(newDate)
    }


    useEffect(() => {
        reportSelections.map((data) => {
            if (data.type === "Merchants" && userType === "Merchants") {
                setReportArr(prevArr => [...prevArr, data])
            }
        })
    }, [])

    useEffect(() => {
        if (reportType === "Monthly") {
            setDateFilter(moment(dateFilter).format("MMM YYYY"))
            setHeaders(headersMonthly)
        }
        else {
            setDateFilter(moment(dateFilter).format("MMM DD, YYYY"))
            setHeaders(headersDaily)
        }

    }, [reportType])

    useEffect(() => {
        if (batchData) {
            if (reportType === "Monthly") {
                const filteredDate = batchData.filter((b) => {
                    if (b.reportDate >= moment(dateFilter).startOf('month').format("YYYY-MM-DDT00:00:00")
                        && b.reportDate <= moment(dateFilter).endOf('month').format("YYYY-MM-DDT23:59:59")
                    ) {
                        return true;
                    }
                })
                setData(filteredDate)
            }
            else if (reportType === "Daily") {
                const filteredDate = batchData.filter((b) => {
                    if (b.reportDate >= moment(dateFilter).startOf('day').format("YYYY-MM-DDT00:00:00")
                        && b.reportDate <= moment(dateFilter).endOf('day').format("YYYY-MM-DDT23:59:59")
                    ) {
                        return true;
                    }
                })
                setData(filteredDate)
            }

            else {
                setData([])
            }
        }

    }, [dateFilter, batchData])

    useEffect(() => {
        if (!batchData) {
            const getData = () => {
                let subId = ""
                return db
                    .collection('merchants-live')
                    .where("merchantNumber", "==", userMid)
                    .get()
                    .then((snapshots) => {
                        if (!snapshots.empty) {
                            snapshots.forEach((s) => {
                                s = s.data()
                                subId = s.docId
                                setDocId(s.docId)
                            })
                            // .then(() => {
                            db
                                .collection("merchants-live")
                                .doc(subId)
                                .collection("batch-summary")
                                .orderBy('reportDate', 'desc')
                                .onSnapshot((snapshot) => {
                                    const arr = [];
                                    snapshot.forEach((doc) => {
                                        arr.push(doc.data())
                                    })
                                    setBatchData(arr)
                                    setData(arr)

                                })
                            // })
                        }
                        else {
                            setBatchData([])
                            setData([])
                        }
                    })
            }
            getData()
        }
    })

    useEffect(() => {
        if (data) {
            var arr = []
            setPoints([])
            data.map((d) => {
                const floorDollarPerBassisPts = ptsFormula(d.netAmount, user.userBasisPts)
                arr.push(floorDollarPerBassisPts)
                setPoints(prevArr => [...prevArr, floorDollarPerBassisPts])
            })
        }
        setZippedArr(zip(data, arr))
    }, [data])

    if (!batchData)
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop:"10%"
                }}
            >
                <img style={{ width: "20%" }} src={loader} alt="..." />
            </div>
        );

    return (
        <div>
            <Hidden mdUp implementation='css' >
                <div style={{ marginTop: "50px" }}>
                    <GridContainer >
                        <GridItem xs={12} >
                            <Typography variant="h2" className={classes.titleMobile}>
                                Reports
                            </Typography>
                        </GridItem>

                        <GridItem xs={12} className={classes.noPaddingGrid}>
                            <Card style={{ padding: "25px" }}>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        {batchData.length > 0 ?
                                            <Filter
                                                sortValue={sortValue}
                                                setSortValue={setSortValue}
                                                handleReportToggle={handleReportToggle}
                                                reportType={reportType}
                                                setReportType={setReportType}
                                                dateFilter={dateFilter}
                                                setDateFilter={setDateFilter}
                                                increaseDate={increaseDate}
                                                decreaseDate={decreaseDate}
                                                reportSelections={reportArr}
                                                headers={headers}
                                                csvData={data}
                                                points={points}
                                            />
                                            : null}
                                    </GridItem>

                                    <GridItem xs={12}>
                                        <MobileTable
                                            docId={docId}
                                            batchData={zippedArr}
                                            pageNumber={pageNumber}
                                            setPageNumber={setPageNumber}
                                            index={index}
                                            setIndex={setIndex}
                                            endIndex={mobileEndIndex}
                                            setEndIndex={setMobileEndIndex}
                                            itemsPerPage={itemsPerPageMobile}
                                            headers={headers}
                                            history={history}
                                            points={points}
                                        />
                                    </GridItem>

                                </GridContainer>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </Hidden>


            <Hidden smDown implementation="css">
                <GridContainer>
                    <GridItem xs={12} >
                        <Typography variant="h2" className={classes.title}>
                            Reports
                        </Typography>
                    </GridItem>

                    <GridItem xs={12} style={{ marginTop: "20px" }}>
                        <Card style={{ padding: "25px" }}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    {batchData.length > 0 ?
                                        <Filter
                                            sortValue={sortValue}
                                            setSortValue={setSortValue}
                                            handleReportToggle={handleReportToggle}
                                            reportType={reportType}
                                            setReportType={setReportType}
                                            dateFilter={dateFilter}
                                            setDateFilter={setDateFilter}
                                            increaseDate={increaseDate}
                                            decreaseDate={decreaseDate}
                                            reportSelections={reportArr}
                                            headers={headers}
                                            csvData={data}
                                            points={points}
                                        />
                                        : null
                                    }

                                </GridItem>

                                <GridItem xs={12}>
                                    <Table
                                        docId={docId}
                                        batchData={zippedArr}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumber}
                                        index={index}
                                        setIndex={setIndex}
                                        endIndex={endIndex}
                                        setEndIndex={setEndIndex}
                                        itemsPerPage={itemsPerPage}
                                        handlePageClick={handlePageClick}
                                        headers={headers}
                                        history={history}
                                        points={points}
                                    />
                                </GridItem>
                            </GridContainer>
                        </Card>
                    </GridItem>
                </GridContainer>
            </Hidden>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        userId: state.auth.user.uid,
        userType: state.auth.user.userType,
        userMid: state.auth.user.merchantId
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ReportsPage));