import React, { useEffect } from 'react';
import { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import Filter from './components/Filter';
import NoRewards from './components/NoRewards';

import { addProduct } from 'store/actions/cart';
import { addToWishList } from 'store/actions/wishlistAction';
import { removeFromWishlist } from 'store/actions/wishlistAction';

import moment from "moment"

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';



// mui core
import { CardContent, IconButton, Typography, Hidden } from '@material-ui/core';
import ReactPaginate from "react-paginate";
import "views/Pages/reports/batch/components/pagination.css";

// @material-ui/icons
import { ReactComponent as Info } from "assets/img/tuzo/icons/Info.svg";
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { AiFillGift } from 'react-icons/ai';
import { ReactComponent as Diamond } from "assets/img/tuzo/icons/Diamond.svg";
import { ReactComponent as Trip } from "assets/img/tuzo/icons/TripPkg.svg";
import { ReactComponent as Computer } from "assets/img/tuzo/icons/Computer.svg";
import { ReactComponent as Experience } from "assets/img/tuzo/icons/Experience.svg";
import { ReactComponent as GiftCard } from "assets/img/tuzo/icons/GiftCard.svg";
import { ReactComponent as CaretLeft } from "assets/img/tuzo/icons/CaretLeft.svg";
import { ReactComponent as CaretRight } from "assets/img/tuzo/icons/CaretRight.svg";
import { ReactComponent as NewRaffleIcon } from "assets/img/tuzo/icons/NewRaffleIcon.svg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import tuzoBg from "assets/img/tuzo/carouselBackground.png";
import "./carousel.css"
import loader from "assets/img/Tuzo-Preloader-Transparent.gif";
import { filter } from 'lodash';



const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1900 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 1900, min: 1366 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1350, min: 1024 },
        items: 3,
    },
    mobile: {
        breakpoint: { max: 1000, min: 0 },
        items: 1,
    },
};

const buttonNames = [
    "All",
    "Luxury Goods",
    "Trip Packages",
    "Electronics",
    "Experiences",
    "Gift Cards",
    "Raffles"
]

const sortArr = [
    "Recently Added",
    "Price: Low to High",
    "Price: High to Low",
    "Title: A-Z",
    "Title: Z-A",
]


export function RewardsPage(props) {
    const {
        classes,
        history,
        userId,
        filterName,
        addProduct,
        cart,
        rewards,
        wishlist,
        wishlistLoaded,
        addToWishList,
        removeFromWishlist,
        userCompany
    } = props;

    const [buttonFilter, setButtonFilter] = useState(filterName ? filterName : "All")
    const [searchValue, setSearchValue] = useState("")
    const [sortValue, setSortValue] = useState("Recently Added")
    const [copyArr, setCopyArr] = useState(false)
    const [arr, setArr] = useState(false)
    const [wishListArr, setWishListArr] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const itemsPerPageMobile = 5;
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0);
    const [mobileEndIndex, setMobileEndIndex] = useState(itemsPerPageMobile)

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPageMobile);
        setMobileEndIndex(selected * itemsPerPageMobile + itemsPerPageMobile);
    };

    useEffect(() => {
        if (arr.length > 0) {
            arr.slice(index, mobileEndIndex);
        }
    }, [index]);

    useEffect(() => {
        let filteredArr = []
        if (rewards.length > 0 && wishlistLoaded) {
            var filteredTime = rewards.sort(function (a, b) {
                return b.timestamp.seconds - a.timestamp.seconds;
            })
            if (filterName) {
                let valueLowerCase = ""
                if (buttonFilter === "Trip Packages") {
                    valueLowerCase = "travel"
                } else {
                    valueLowerCase = buttonFilter.toLowerCase().replace(/ +/g, "")
                }
                Object.keys(filteredTime).map((key) => {
                    let val = filteredTime[key]["categories"];
                    Object.keys(val).map((valKey) => {
                        if (valKey.toLowerCase() === valueLowerCase && val[valKey] === true) {
                            filteredArr.push(filteredTime[key])
                        }
                    })
                });
                setArr([...filteredArr])
                setCopyArr(rewards)
                setWishListArr(wishlist)
                setDataLoaded(true)
            }
            else {
                setArr([...filteredTime])
                setCopyArr(rewards)
                setWishListArr(wishlist)
                setDataLoaded(true)
            }



        }
    }, [rewards, wishlist])

    const getBgImage = () => {
        return tuzoBg;
    }

    const handleButtonFilter = (value) => {
        let valueLowerCase = ""
        if (value === "Trip Packages") {
            valueLowerCase = "travel"
        } else {
            valueLowerCase = value.toLowerCase().replace(/ +/g, "")
        }
        setButtonFilter(value)
        let filteredArr = []
        if (value === "All") {
            setArr(copyArr)
        }
        else {
            Object.keys(copyArr).map((key) => {
                let val = copyArr[key]["categories"];
                Object.keys(val).map((valKey) => {
                    if (valKey.toLowerCase() === valueLowerCase && val[valKey] === true) {
                        filteredArr.push(copyArr[key])
                    }
                })
            });
            setArr(filteredArr)

        }
    }

    const CustomRightArrow = ({ onClick }) => (
        <button
            className={classes.rightArrow}
            onClick={onClick}>
            <CaretRight />
        </button>
    );
    const CustomLeftArrow = ({ onClick }) => (
        <button
            className={classes.leftArrow}
            onClick={onClick}
        >
            <CaretLeft />
        </button>
    );

    const handleFilter = (value) => {
        if (value === "Recently Added") {
            const filtered = arr.sort(function (a, b) {
                return b.timestamp.seconds - a.timestamp.seconds;
            })
            setIndex(0)
            setMobileEndIndex(itemsPerPageMobile)
            setPageNumber(0)
            setArr([...filtered])
        }
        if (value === "Price: Low to High") {
            const filtered = arr.sort((a, b) =>
                (a.salePricePoints).toString().localeCompare((b.salePricePoints).toString(),
                    undefined, {
                    numeric: true,
                    sensitivity: 'base'
                }))
            setArr([...filtered])
            setIndex(0)
            setMobileEndIndex(itemsPerPageMobile)
            setPageNumber(0)
        }
        if (value === "Price: High to Low") {
            const filtered = arr.sort((a, b) => (b.salePricePoints).toString().localeCompare((a.salePricePoints).toString(), undefined, { numeric: true }))
            setArr(filtered)
            setIndex(0)
            setMobileEndIndex(itemsPerPageMobile)
            setPageNumber(0)

        }
        if (value === "Title: A-Z") {
            const filtered = arr.sort(function (a, b) {
                if (a.rewardName < b.rewardName) { return -1; }
                if (a.rewardName > b.rewardName) { return 1; }
                return 0;
            })
            setArr(filtered)
            setIndex(0)
            setMobileEndIndex(itemsPerPageMobile)
            setPageNumber(0)
        }
        if (value === "Title: Z-A") {
            const filtered = arr.sort(function (a, b) {
                if (a.rewardName.toLowerCase() > b.rewardName.toLowerCase()) { return -1; }
                if (a.rewardName.toLowerCase() < b.rewardName.toLowerCase()) { return 1; }
                return 0;
            })
            setArr(filtered)
            setIndex(0)
            setMobileEndIndex(itemsPerPageMobile)
            setPageNumber(0)
        }
    }

    const handleAddWishlist = (index) => {
        let newArr = [...arr]
        newArr[index]["wishlist"] = true
        setArr(newArr)
    }

    const handleRemoveFromWishlist = (index) => {
        let newArr = [...arr]
        newArr[index]["wishlist"] = false
        setArr(newArr)
    }

    useEffect(() => {
        if (dataLoaded) {
            let newArr = [...arr]
            newArr.map((data, index) => {
                if (JSON.stringify(cart).includes(data.rewardId)) {
                    newArr[index].added = true
                }
                if (JSON.stringify(wishListArr).includes(data.rewardId)) {
                    newArr[index].wishlist = true
                }
                else {
                    newArr[index].added = false
                    newArr[index].wishlist = false
                }
            })
            setArr(newArr)
        }
    }, [dataLoaded])

    const checkedAdded = (index) => {
        let newArr = [...arr]
        newArr[index]["added"] = true
        setArr(newArr)
    }

    const handleSearch = () => {
        if (searchValue) {
            const filtered = copyArr.filter((f) => {
                if (
                    f.rewardName.toLowerCase().includes(searchValue.toLowerCase())
                ) {
                    return true
                }
            })
            setArr(filtered)
        }
        else {
            setArr(copyArr)
        }
    }

    if (!dataLoaded)
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop:"10%"
                }}
            >
                <img style={{ width: "20%" }} src={loader} alt="..." />
            </div>
        );

    return (
        <div>
            <Hidden mdUp implementation='css'>
                <div
                    style={{
                        backgroundImage: "url(" + getBgImage() + ")",
                        width: "100vw",
                        marginLeft: "-30px",
                        height: "100%",
                        marginTop: "30px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#274b73",
                        backgroundPhoto: "repeat",
                        backgroundSize: "contain",
                        padding: "30px 20px"
                    }}
                >
                    <GridContainer>
                        <GridItem xs={12}>
                            <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                                <Typography variant="h2" className={classes.rewardsBannerTitle} style={{ marginTop: "15px", }}>
                                    Explore the Possibilities
                                </Typography >

                                <Typography variant="h2" className={classes.rewardsBannerDesc} style={{ marginTop: "15px" }}>
                                    Take a trip, pamper yourself, or buy that luxury item!
                                </Typography>
                            </div>
                        </GridItem>

                        <GridItem xs={12} style={{ marginTop: "10px" }}>
                            <Carousel
                                autoPlay={false}
                                autoPlaySpeed={3000}
                                infinite={true}
                                swipeable={true}
                                containerClass="carousel-container"
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                                draggable={false}
                                showDots={false}
                                responsive={responsive}
                                style={{ display: "flex", alignItems: "center" }}
                                arrows={true}
                                customLeftArrow={<CustomLeftArrow />}
                                customRightArrow={<CustomRightArrow />}
                            >
                                {buttonNames.map((name) => {
                                    return (
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <Button
                                                onClick={() => handleButtonFilter(name)}
                                                style={buttonFilter === name ? { background: "#56CA93" } : {}}
                                                className={classes.rewardsBannerButton}
                                            >
                                                {name === "All" ?
                                                    <AiFillGift className={classes.icon} style={{ marginRight: "10px" }} />
                                                    :
                                                    name === "Luxury Goods" ?
                                                        <Diamond className={classes.icon} />
                                                        :
                                                        name === "Trip Packages" ?
                                                            <Trip className={classes.icon} />
                                                            :
                                                            name === "Electronics" ?
                                                                <Computer className={classes.icon} />
                                                                :
                                                                name === "Experiences" ?
                                                                    <Experience className={classes.icon} />
                                                                    :
                                                                    name === "Gift Cards" ?
                                                                        <GiftCard className={classes.icon} />
                                                                        :
                                                                        <NewRaffleIcon className={classes.icon} />
                                                }
                                                {name}
                                            </Button>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </GridItem>
                    </GridContainer>
                </div>

                <GridContainer>
                    {arr.length < 1 ?
                        <>
                            <GridItem xs={12}>
                                <NoRewards
                                    classes={classes}
                                    handleButtonFilter={handleButtonFilter}
                                    buttonFilter={buttonFilter}
                                />
                            </GridItem>
                        </>
                        :
                        <>
                            <GridItem xs={12} style={{ marginTop: "10px" }}>
                                <Filter
                                    sortArr={sortArr}
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    sortValue={sortValue}
                                    setSortValue={setSortValue}
                                    handleSearch={handleSearch}
                                    handleFilter={handleFilter}
                                    history={history}
                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    {arr.slice(
                                        pageNumber * itemsPerPageMobile,
                                        pageNumber * itemsPerPageMobile + itemsPerPageMobile
                                    )
                                        .map((data, index) => (
                                            <>
                                                <GridItem xs={12} className={classes.gridSpace} key={index}>
                                                    <Card className={classes.rewardsCardListStylingMobile} key={index}>
                                                        <CardContent style={{ marginTop: "20px", marginBottom: "0px" }}>
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <IconButton
                                                                    onClick={() => history.push(
                                                                        `/iso-admin/${userCompany}/reward-detail/${data.rewardId}`,
                                                                        {
                                                                            rewardData: data
                                                                        }
                                                                    )}
                                                                >
                                                                    <Info />
                                                                </IconButton>
                                                                {!data.wishlist ?
                                                                    <>
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                handleAddWishlist(index)
                                                                                addToWishList(data, userId)
                                                                            }}
                                                                            style={{ color: "#E2A400" }}
                                                                        >
                                                                            <AiOutlineHeart />
                                                                        </IconButton>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                handleRemoveFromWishlist(index)
                                                                                removeFromWishlist(data, userId, index)
                                                                            }}
                                                                            style={{ color: "#E2A400" }}
                                                                        >
                                                                            <AiFillHeart />
                                                                        </IconButton>
                                                                    </>
                                                                }
                                                            </div>

                                                            <div
                                                                className={classes.divHover}
                                                                onClick={() => history.push(
                                                                    `/iso-admin/${userCompany}/reward-detail/${data.rewardId}`,
                                                                    {
                                                                        rewardData: data
                                                                    }
                                                                )}
                                                            >
                                                                <img
                                                                    style={{ width: "50%" }}
                                                                    src={data.mainImage}
                                                                    alt="img here"
                                                                />

                                                                <Typography variant="h2" className={classes.cardListTitleNoOverflow}>
                                                                    {/* {(data.rewardName).length < 33 ? "" : null} */}
                                                                    {data.rewardName}
                                                                </Typography>
                                                            </div>

                                                            <GridContainer>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "space-between",
                                                                        width: "93%",
                                                                        padding: "5px"
                                                                    }}>
                                                                    <GridItem xs={6}>
                                                                        <Typography variant="h2" className={classes.cardListDesc}>
                                                                            Points Price
                                                                        </Typography>
                                                                    </GridItem>

                                                                    <GridItem xs={6}>
                                                                        <Typography variant="h2" className={classes.cardListPtsMobile}>
                                                                            {(data.salePricePoints).toLocaleString()}
                                                                        </Typography>
                                                                    </GridItem>
                                                                </div>

                                                                <GridItem xs={12} style={{ display: "flex", justifyContent: "center" }}>

                                                                    <Button
                                                                        className={classes.addToCartButtonMobile}
                                                                        onClick={() => history.push(
                                                                            `/iso-admin/${userCompany?.replace(/ /g, '').toLowerCase()}/reward-detail/${data.rewardId}`,
                                                                            {
                                                                                rewardData: data
                                                                            }
                                                                        )}
                                                                    >
                                                                        View Details
                                                                    </Button>
                                                                </GridItem>
                                                            </GridContainer>

                                                        </CardContent>
                                                    </Card>
                                                </GridItem>
                                            </>
                                        ))}
                                </GridContainer>
                            </GridItem>
                        </>
                    }
                </GridContainer>
                <GridContainer style={{ marginBottom: "-20px" }}>
                    <GridItem xs={8} style={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
                        <h5
                            className={classes.indexes}
                        >
                            {index + 1} - {mobileEndIndex} of{" "}
                            {Math.ceil(arr.length / itemsPerPageMobile)}
                        </h5>
                    </GridItem>
                    <GridItem xs={4}>
                        <ReactPaginate
                            forcePage={pageNumber}
                            paginationSize={0}
                            onPageChange={handlePageClick}
                            pageCount={Math.ceil(arr.length / itemsPerPageMobile)}
                            nextLabel={
                                <ArrowRight
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                        marginTop: "-2px"
                                    }}
                                />
                            }
                            previousLabel={
                                <ArrowLeft
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                        marginTop: "-2px"
                                    }}
                                />
                            }
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </GridItem>
                </GridContainer>
            </Hidden>

            <Hidden smDown implementation="css">
                <div
                    style={{
                        backgroundImage: "url(" + getBgImage() + ")",
                        width: "100vw",
                        marginLeft: "-30px",
                        height: "100%",
                        marginTop: "-30px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#274b73",
                        backgroundPhoto: "repeat",
                        backgroundSize: "contain",
                        padding: "30px 20px"
                    }}
                >
                    <GridContainer style={{ marginLeft: "30px" }}>
                        <GridItem xs={4}>
                            <div >
                                <Typography variant="h2" className={classes.rewardsBannerTitle}>
                                    Explore the Possibilities
                                </Typography >

                                <Typography variant="h2" className={classes.rewardsBannerDesc} style={{ marginTop: "15px" }}>
                                    Take a trip, pamper yourself, or buy that luxury item!
                                </Typography>
                            </div>
                        </GridItem>

                        <GridItem xs={7}>
                            <Carousel
                                autoPlay={false}
                                autoPlaySpeed={3000}
                                infinite={true}
                                swipeable={true}
                                containerClass="carousel-container"
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                                draggable={false}
                                showDots={false}
                                responsive={responsive}
                                style={{ display: "flex" }}
                                arrows={true}
                                customLeftArrow={<CustomLeftArrow />}
                                customRightArrow={<CustomRightArrow />}
                            >
                                {buttonNames.map((name) => {
                                    return (
                                        <Button
                                            onClick={() => handleButtonFilter(name)}
                                            style={buttonFilter === name ? { background: "#56CA93" } : {}}
                                            className={classes.rewardsBannerButton}
                                        >
                                            {name === "All" ?
                                                <AiFillGift className={classes.icon} style={{ marginRight: "10px" }} />
                                                :
                                                name === "Luxury Goods" ?
                                                    <Diamond className={classes.icon} />
                                                    :
                                                    name === "Trip Packages" ?
                                                        <Trip className={classes.icon} />
                                                        :
                                                        name === "Electronics" ?
                                                            <Computer className={classes.icon} />
                                                            :
                                                            name === "Experiences" ?
                                                                <Experience className={classes.icon} />
                                                                :
                                                                name === "Gift Cards" ?
                                                                    <GiftCard className={classes.icon} />
                                                                    :
                                                                    <NewRaffleIcon className={classes.icon} />
                                            }

                                            {name}
                                        </Button>
                                    )

                                })}
                            </Carousel>
                        </GridItem>
                    </GridContainer>
                </div>

                <GridContainer>
                    {arr.length < 1 ?
                        <>
                            <GridItem xs={12}>
                                <NoRewards
                                    classes={classes}
                                    handleButtonFilter={handleButtonFilter}
                                />
                            </GridItem>
                        </>
                        :
                        <>
                            <GridItem xs={12} style={{ marginTop: "10px" }}>
                                <Filter
                                    sortArr={sortArr}
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    sortValue={sortValue}
                                    setSortValue={setSortValue}
                                    handleSearch={handleSearch}
                                    handleFilter={handleFilter}
                                    history={history}
                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    {arr.map((data, index) => (
                                        <>
                                            <GridItem xs={4} md={4} xl={3} className={classes.gridSpace} key={index}>
                                                <Card className={classes.rewardsCardListStyling} key={index}>
                                                    <CardContent style={{ padding: "0px" }}>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <IconButton onClick={() => history.push(
                                                                `/iso-admin/${userCompany}/reward-detail/${data.rewardId}`,
                                                                {
                                                                    rewardData: data
                                                                }
                                                            )}
                                                            >
                                                                <Info />
                                                            </IconButton>
                                                            {!data.wishlist ?
                                                                <>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            handleAddWishlist(index)
                                                                            addToWishList(data, userId)
                                                                        }}
                                                                        style={{ color: "#E2A400" }}
                                                                    >
                                                                        <AiOutlineHeart />
                                                                    </IconButton>
                                                                </>
                                                                :
                                                                <>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            handleRemoveFromWishlist(index)
                                                                            removeFromWishlist(data, userId, index)
                                                                        }}
                                                                        style={{ color: "#E2A400" }}
                                                                    >
                                                                        <AiFillHeart />
                                                                    </IconButton>
                                                                </>
                                                            }
                                                        </div>

                                                        <div
                                                            className={classes.divHover}
                                                            onClick={() => history.push(
                                                                `/iso-admin/${userCompany}/reward-detail/${data.rewardId}`,
                                                                {
                                                                    rewardData: data
                                                                }
                                                            )}
                                                        >
                                                            <img
                                                                className={classes.cardListImg}
                                                                src={data.mainImage}
                                                                alt="img here"
                                                            />

                                                            <Typography variant="h2" className={classes.cardListTitleNoOverflow}>
                                                                {(data.rewardName).length < 33 ? <br /> : null}
                                                                {data.rewardName}
                                                            </Typography>
                                                        </div>

                                                        <div className={classes.divStyle} >
                                                            <div>
                                                                <Typography variant="h2" className={classes.cardListDesc}>
                                                                    Points Price
                                                                </Typography>
                                                                <Typography variant="h2" className={classes.cardListPts}>
                                                                    {(data.salePricePoints).toLocaleString()}
                                                                </Typography>
                                                            </div>

                                                            <div style={{ display: "flex" }}>
                                                                <Button
                                                                    className={classes.addToCartButton}
                                                                    onClick={() => history.push(
                                                                        `/iso-admin/${userCompany}/reward-detail/${data.rewardId}`,
                                                                        {
                                                                            rewardData: data
                                                                        }
                                                                    )}
                                                                >
                                                                    View Details
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </GridItem>
                                        </>
                                    ))}
                                </GridContainer>
                            </GridItem>
                        </>
                    }
                </GridContainer>
            </Hidden>
        </div>
    );
}

function mapStateToProps(state, location) {
    return {
        userId: state.auth.user.uid,
        filterName: location.history.location.state?.filterName,
        cart: state.cart.cart,
        rewards: state.rewards.rewards,
        wishlist: state.wishlist.wishlist,
        wishlistLoaded: state.wishlist.initDataRetrieved,
        userCompany: state.auth.user.companyName
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        addProduct: (product) => dispatch(addProduct(product)),
        addToWishList: (data, uid) => dispatch(addToWishList(data, uid)),
        removeFromWishlist: (data, uid, index) => dispatch(removeFromWishlist(data, uid, index))
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(RewardsPage));
